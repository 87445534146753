import { actionTypes } from '../../../../../constants/actionTypes'
import { 
    BackgroundCheckRequestGetCandidateFormFailure, BackgroundCheckRequestGetCandidateFormFailurePayload, BackgroundCheckRequestGetCandidateFormPayload, 
    BackgroundCheckRequestGetCandidateFormRequest, BackgroundCheckRequestGetCandidateFormSuccess, BackgroundCheckRequestGetCandidateFormSuccessPayload,

    BackgroundCheckRequestValidateCandidateFormPayload, BackgroundCheckRequestValidateCandidateFormRequest, BackgroundCheckRequestValidateCandidateFormSuccessPayload, 
    BackgroundCheckRequestValidateCandidateFormSuccess, BackgroundCheckRequestValidateCandidateFormFailurePayload, BackgroundCheckRequestValidateCandidateFormFailure, 
    
    BackgroundCheckRequestCreateCandidateFormPayload, BackgroundCheckRequestCreateCandidateFormRequest, BackgroundCheckRequestCreateCandidateFormSuccessPayload, 
    BackgroundCheckRequestCreateCandidateFormSuccess, BackgroundCheckRequestCreateCandidateFormFailurePayload, BackgroundCheckRequestCreateCandidateFormFailure,

    BackgroundCheckRequestAnswerUploadFailure, BackgroundCheckRequestAnswerUploadFailurePayload, BackgroundCheckRequestAnswerUploadPayload, 
    BackgroundCheckRequestAnswerUploadRequest, BackgroundCheckRequestAnswerUploadSuccess, BackgroundCheckRequestAnswerUploadSuccessPayload, 
    
    BackgroundCheckRequestReportOverviewPayload, BackgroundCheckRequestReportOverviewRequest, BackgroundCheckRequestReportOverviewSuccessPayload, 
    BackgroundCheckRequestReportOverviewSuccess, BackgroundCheckRequestReportOverviewFailurePayload, BackgroundCheckRequestReportOverviewFailure, 
    
    BackgroundCheckRequestReportChecklistPayload, BackgroundCheckRequestReportChecklistRequest, BackgroundCheckRequestReportChecklistSuccessPayload, 
    BackgroundCheckRequestReportChecklistSuccess, BackgroundCheckRequestReportChecklistFailurePayload, BackgroundCheckRequestReportChecklistFailure,
    
    BackgroundCheckRequestReportDetailPayload, BackgroundCheckRequestReportDetailRequest, BackgroundCheckRequestReportDetailSuccessPayload, 
    BackgroundCheckRequestReportDetailSuccess, BackgroundCheckRequestReportDetailFailurePayload, BackgroundCheckRequestReportDetailFailure, 
    
    BackgroundCheckRequestReportSetStatusPayload, BackgroundCheckRequestReportSetStatusRequest, BackgroundCheckRequestReportSetStatusSuccessPayload, 
    BackgroundCheckRequestReportSetStatusSuccess, BackgroundCheckRequestReportSetStatusFailurePayload, BackgroundCheckRequestReportSetStatusFailure, 
    
    BackgroundCheckRequestGetPricePayload, BackgroundCheckRequestGetPriceRequest, BackgroundCheckRequestGetPriceSuccessPayload, 
    BackgroundCheckRequestGetPriceSuccess, BackgroundCheckRequestGetPriceFailurePayload, BackgroundCheckRequestGetPriceFailure, 
    
    BackgroundCheckRequestMakePaymentPayload, BackgroundCheckRequestMakePaymentRequest, BackgroundCheckRequestMakePaymentSuccessPayload, 
    BackgroundCheckRequestMakePaymentSuccess, BackgroundCheckRequestMakePaymentFailurePayload, BackgroundCheckRequestMakePaymentFailure, BackgroundCheckRequestFileUploadPayload, BackgroundCheckRequestFileUploadRequest, BackgroundCheckRequestFileUploadSuccessPayload, BackgroundCheckRequestFileUploadSuccess, BackgroundCheckRequestFileUploadFailurePayload, BackgroundCheckRequestFileUploadFailure, 
    
    BackgroundCheckRequestCandidatePaymentDetailsPayload, BackgroundCheckRequestCandidatePaymentDetailsRequest, BackgroundCheckRequestCandidatePaymentDetailsSuccessPayload,
    BackgroundCheckRequestCandidatePaymentDetailsSuccess, BackgroundCheckRequestCandidatePaymentDetailsFailurePayload, BackgroundCheckRequestCandidatePaymentDetailsFailure,
    
    BackgroundCheckRequestCandidatePaymentMpessaInitPayload, BackgroundCheckRequestCandidatePaymentMpessaInitRequest, BackgroundCheckRequestCandidatePaymentMpessaInitSuccessPayload,
    BackgroundCheckRequestCandidatePaymentMpessaInitSuccess, BackgroundCheckRequestCandidatePaymentMpessaInitFailurePayload, BackgroundCheckRequestCandidatePaymentMpessaInitFailure,
    
    BackgroundCheckRequestCandidatePaymentMpessaVerifyPayload, BackgroundCheckRequestCandidatePaymentMpessaVerifyRequest, BackgroundCheckRequestCandidatePaymentMpessaVerifySuccessPayload,
    BackgroundCheckRequestCandidatePaymentMpessaVerifySuccess, BackgroundCheckRequestCandidatePaymentMpessaVerifyFailurePayload, BackgroundCheckRequestCandidatePaymentMpessaVerifyFailure,
    
    BackgroundCheckRequestReportDetailDownloadPayload, BackgroundCheckRequestReportDetailDownloadRequest, BackgroundCheckRequestReportDetailDownloadSuccessPayload,
    BackgroundCheckRequestReportDetailDownloadSuccess, BackgroundCheckRequestReportDetailDownloadFailurePayload, BackgroundCheckRequestReportDetailDownloadFailure, 
    
    BackgroundCheckRequestGetPackageCandidateFormPayload, BackgroundCheckRequestGetPackageCandidateFormRequest, BackgroundCheckRequestGetPackageCandidateFormSuccessPayload,
    BackgroundCheckRequestGetPackageCandidateFormSuccess, BackgroundCheckRequestGetPackageCandidateFormFailurePayload, BackgroundCheckRequestGetPackageCandidateFormFailure,
    
    BackgroundCheckRequestGetSubmittedFormPayload, BackgroundCheckRequestGetSubmittedFormRequest, BackgroundCheckRequestGetSubmittedFormSuccessPayload,
    BackgroundCheckRequestGetSubmittedFormSuccess, BackgroundCheckRequestGetSubmittedFormFailurePayload, BackgroundCheckRequestGetSubmittedFormFailure,
    
    BackgroundCheckRequestUpdateSubmittedFormPayload, BackgroundCheckRequestUpdateSubmittedFormRequest, BackgroundCheckRequestUpdateSubmittedFormSuccessPayload,
    BackgroundCheckRequestUpdateSubmittedFormSuccess, BackgroundCheckRequestUpdateSubmittedFormFailurePayload, BackgroundCheckRequestUpdateSubmittedFormFailure,
    BackgroundCheckRequestReportDownloadPayload,
    BackgroundCheckRequestReportDownloadRequest,
    BackgroundCheckRequestReportDownloadSuccessPayload,
    BackgroundCheckRequestReportDownloadSuccess,
    BackgroundCheckRequestReportDownloadFailurePayload,
    BackgroundCheckRequestReportDownloadFailure,
    BackgroundCheckRequestReportAdjudicationPayload,
    BackgroundCheckRequestReportAdjudicationRequest,
    BackgroundCheckRequestReportAdjudicationSuccessPayload,
    BackgroundCheckRequestReportAdjudicationSuccess,
    BackgroundCheckRequestReportAdjudicationFailurePayload,
    BackgroundCheckRequestReportAdjudicationFailure,
    BackgroundCheckRequestReferenceAnswerUploadPayload,
    BackgroundCheckRequestReferenceAnswerUploadRequest,
    BackgroundCheckRequestReferenceAnswerUploadSuccessPayload,
    BackgroundCheckRequestReferenceAnswerUploadSuccess,
    BackgroundCheckRequestReferenceAnswerUploadFailurePayload,
    BackgroundCheckRequestReferenceAnswerUploadFailure,
    BackgroundCheckRequestReferenceInfoPayload,
    BackgroundCheckRequestReferenceInfoRequest,
    BackgroundCheckRequestReferenceInfoSuccessPayload,
    BackgroundCheckRequestReferenceInfoSuccess,
    BackgroundCheckRequestReferenceInfoFailurePayload,
    BackgroundCheckRequestReferenceInfoFailure,
    BackgroundCheckRequestReferenceOrgPayload,
    BackgroundCheckRequestReferenceOrgRequest,
    BackgroundCheckRequestReferenceOrgSuccessPayload,
    BackgroundCheckRequestReferenceOrgSuccess,
    BackgroundCheckRequestReferenceOrgFailurePayload,
    BackgroundCheckRequestReferenceOrgFailure,
} from './types';


export const backgroundCheckRequestGetCandidateFormRequest = (payload:BackgroundCheckRequestGetCandidateFormPayload):BackgroundCheckRequestGetCandidateFormRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_GET_CANDIDATE_FORM_REQUEST,
    payload,
});
export const backgroundCheckRequestGetCandidateFormSuccess = (payload:BackgroundCheckRequestGetCandidateFormSuccessPayload):BackgroundCheckRequestGetCandidateFormSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_GET_CANDIDATE_FORM_SUCCESS,
    payload,
});
export const backgroundCheckRequestGetCandidateFormFailure = (payload:BackgroundCheckRequestGetCandidateFormFailurePayload):BackgroundCheckRequestGetCandidateFormFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_GET_CANDIDATE_FORM_FAILURE,
    payload,
});


export const backgroundCheckRequestGetSubmittedFormRequest = (payload:BackgroundCheckRequestGetSubmittedFormPayload):BackgroundCheckRequestGetSubmittedFormRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_GET_SUBMITTED_FORM_REQUEST,
    payload,
});
export const backgroundCheckRequestGetSubmittedFormSuccess = (payload:BackgroundCheckRequestGetSubmittedFormSuccessPayload):BackgroundCheckRequestGetSubmittedFormSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_GET_SUBMITTED_FORM_SUCCESS,
    payload,
});
export const backgroundCheckRequestGetSubmittedFormFailure = (payload:BackgroundCheckRequestGetSubmittedFormFailurePayload):BackgroundCheckRequestGetSubmittedFormFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_GET_SUBMITTED_FORM_FAILURE,
    payload,
});


export const backgroundCheckRequestUpdateSubmittedFormRequest = (payload:BackgroundCheckRequestUpdateSubmittedFormPayload):BackgroundCheckRequestUpdateSubmittedFormRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_UPDATE_SUBMITTED_FORM_REQUEST,
    payload,
});
export const backgroundCheckRequestUpdateSubmittedFormSuccess = (payload:BackgroundCheckRequestUpdateSubmittedFormSuccessPayload):BackgroundCheckRequestUpdateSubmittedFormSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_UPDATE_SUBMITTED_FORM_SUCCESS,
    payload,
});
export const backgroundCheckRequestUpdateSubmittedFormFailure = (payload:BackgroundCheckRequestUpdateSubmittedFormFailurePayload):BackgroundCheckRequestUpdateSubmittedFormFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_UPDATE_SUBMITTED_FORM_FAILURE,
    payload,
});


export const backgroundCheckRequestAnswerUploadRequest = (payload:BackgroundCheckRequestAnswerUploadPayload):BackgroundCheckRequestAnswerUploadRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_ANSWER_UPLOAD_REQUEST,
    payload,
});
export const backgroundCheckRequestAnswerUploadSuccess = (payload:BackgroundCheckRequestAnswerUploadSuccessPayload):BackgroundCheckRequestAnswerUploadSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_ANSWER_UPLOAD_SUCCESS,
    payload,
});
export const backgroundCheckRequestAnswerUploadFailure = (payload:BackgroundCheckRequestAnswerUploadFailurePayload):BackgroundCheckRequestAnswerUploadFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_ANSWER_UPLOAD_FAILURE,
    payload,
});


export const backgroundCheckRequestFileUploadRequest = (payload:BackgroundCheckRequestFileUploadPayload):BackgroundCheckRequestFileUploadRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_FILE_UPLOAD_REQUEST,
    payload,
});
export const backgroundCheckRequestFileUploadSuccess = (payload:BackgroundCheckRequestFileUploadSuccessPayload):BackgroundCheckRequestFileUploadSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_FILE_UPLOAD_SUCCESS,
    payload,
});
export const backgroundCheckRequestFileUploadFailure = (payload:BackgroundCheckRequestFileUploadFailurePayload):BackgroundCheckRequestFileUploadFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_FILE_UPLOAD_FAILURE,
    payload,
});


export const backgroundCheckRequestValidateCandidateFormRequest = (payload:BackgroundCheckRequestValidateCandidateFormPayload):BackgroundCheckRequestValidateCandidateFormRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_VALIDATE_CANDIDATE_FORM_REQUEST,
    payload,
});
export const backgroundCheckRequestValidateCandidateFormSuccess = (payload:BackgroundCheckRequestValidateCandidateFormSuccessPayload):BackgroundCheckRequestValidateCandidateFormSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_VALIDATE_CANDIDATE_FORM_SUCCESS,
    payload,
});
export const backgroundCheckRequestValidateCandidateFormFailure = (payload:BackgroundCheckRequestValidateCandidateFormFailurePayload):BackgroundCheckRequestValidateCandidateFormFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_VALIDATE_CANDIDATE_FORM_FAILURE,
    payload,
});


export const backgroundCheckRequestGetPackageCandidateFormRequest = (payload:BackgroundCheckRequestGetPackageCandidateFormPayload):BackgroundCheckRequestGetPackageCandidateFormRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_GET_PACKAGE_CANDIDATE_FORM_REQUEST,
    payload,
});
export const backgroundCheckRequestGetPackageCandidateFormSuccess = (payload:BackgroundCheckRequestGetPackageCandidateFormSuccessPayload):BackgroundCheckRequestGetPackageCandidateFormSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_GET_PACKAGE_CANDIDATE_FORM_SUCCESS,
    payload,
});
export const backgroundCheckRequestGetPackageCandidateFormFailure = (payload:BackgroundCheckRequestGetPackageCandidateFormFailurePayload):BackgroundCheckRequestGetPackageCandidateFormFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_GET_PACKAGE_CANDIDATE_FORM_FAILURE,
    payload,
});


export const backgroundCheckRequestCreateCandidateFormRequest = (payload:BackgroundCheckRequestCreateCandidateFormPayload):BackgroundCheckRequestCreateCandidateFormRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_CREATE_CANDIDATE_FORM_REQUEST,
    payload,
});
export const backgroundCheckRequestCreateCandidateFormSuccess = (payload:BackgroundCheckRequestCreateCandidateFormSuccessPayload):BackgroundCheckRequestCreateCandidateFormSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_CREATE_CANDIDATE_FORM_SUCCESS,
    payload,
});
export const backgroundCheckRequestCreateCandidateFormFailure = (payload:BackgroundCheckRequestCreateCandidateFormFailurePayload):BackgroundCheckRequestCreateCandidateFormFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_CREATE_CANDIDATE_FORM_FAILURE,
    payload,
});


export const backgroundCheckRequestReportOverviewRequest = (payload:BackgroundCheckRequestReportOverviewPayload):BackgroundCheckRequestReportOverviewRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_OVERVIEW_REQUEST,
    payload,
});
export const backgroundCheckRequestReportOverviewSuccess = (payload:BackgroundCheckRequestReportOverviewSuccessPayload):BackgroundCheckRequestReportOverviewSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_OVERVIEW_SUCCESS,
    payload,
});
export const backgroundCheckRequestReportOverviewFailure = (payload:BackgroundCheckRequestReportOverviewFailurePayload):BackgroundCheckRequestReportOverviewFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_OVERVIEW_FAILURE,
    payload,
});


export const backgroundCheckRequestReportChecklistRequest = (payload:BackgroundCheckRequestReportChecklistPayload):BackgroundCheckRequestReportChecklistRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_CHECKLIST_REQUEST,
    payload,
});
export const backgroundCheckRequestReportChecklistSuccess = (payload:BackgroundCheckRequestReportChecklistSuccessPayload):BackgroundCheckRequestReportChecklistSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_CHECKLIST_SUCCESS,
    payload,
});
export const backgroundCheckRequestReportChecklistFailure = (payload:BackgroundCheckRequestReportChecklistFailurePayload):BackgroundCheckRequestReportChecklistFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_CHECKLIST_FAILURE,
    payload,
});


export const backgroundCheckRequestReportDetailRequest = (payload:BackgroundCheckRequestReportDetailPayload):BackgroundCheckRequestReportDetailRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_DETAIL_REQUEST,
    payload,
});
export const backgroundCheckRequestReportDetailSuccess = (payload:BackgroundCheckRequestReportDetailSuccessPayload):BackgroundCheckRequestReportDetailSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_DETAIL_SUCCESS,
    payload,
});
export const backgroundCheckRequestReportDetailFailure = (payload:BackgroundCheckRequestReportDetailFailurePayload):BackgroundCheckRequestReportDetailFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_DETAIL_FAILURE,
    payload,
});


export const backgroundCheckRequestReportDetailDownloadRequest = (payload:BackgroundCheckRequestReportDetailDownloadPayload):BackgroundCheckRequestReportDetailDownloadRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_DETAIL_DOWNLOAD_REQUEST,
    payload,
});
export const backgroundCheckRequestReportDetailDownloadSuccess = (payload:BackgroundCheckRequestReportDetailDownloadSuccessPayload):BackgroundCheckRequestReportDetailDownloadSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_DETAIL_DOWNLOAD_SUCCESS,
    payload,
});
export const backgroundCheckRequestReportDetailDownloadFailure = (payload:BackgroundCheckRequestReportDetailDownloadFailurePayload):BackgroundCheckRequestReportDetailDownloadFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_DETAIL_DOWNLOAD_FAILURE,
    payload,
});


export const backgroundCheckRequestReportDownloadRequest = (payload:BackgroundCheckRequestReportDownloadPayload):BackgroundCheckRequestReportDownloadRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_DOWNLOAD_REQUEST,
    payload,
});
export const backgroundCheckRequestReportDownloadSuccess = (payload:BackgroundCheckRequestReportDownloadSuccessPayload):BackgroundCheckRequestReportDownloadSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_DOWNLOAD_SUCCESS,
    payload,
});
export const backgroundCheckRequestReportDownloadFailure = (payload:BackgroundCheckRequestReportDownloadFailurePayload):BackgroundCheckRequestReportDownloadFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_DOWNLOAD_FAILURE,
    payload,
});

export const backgroundCheckRequestReportSetStatusRequest = (payload:BackgroundCheckRequestReportSetStatusPayload):BackgroundCheckRequestReportSetStatusRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_SET_STATUS_REQUEST,
    payload,
});
export const backgroundCheckRequestReportSetStatusSuccess = (payload:BackgroundCheckRequestReportSetStatusSuccessPayload):BackgroundCheckRequestReportSetStatusSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_SET_STATUS_SUCCESS,
    payload,
});
export const backgroundCheckRequestReportSetStatusFailure = (payload:BackgroundCheckRequestReportSetStatusFailurePayload):BackgroundCheckRequestReportSetStatusFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_SET_STATUS_FAILURE,
    payload,
});


export const backgroundCheckRequestGetPriceRequest = (payload:BackgroundCheckRequestGetPricePayload):BackgroundCheckRequestGetPriceRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_GET_PRICE_REQUEST,
    payload,
});
export const backgroundCheckRequestGetPriceSuccess = (payload:BackgroundCheckRequestGetPriceSuccessPayload):BackgroundCheckRequestGetPriceSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_GET_PRICE_SUCCESS,
    payload,
});
export const backgroundCheckRequestGetPriceFailure = (payload:BackgroundCheckRequestGetPriceFailurePayload):BackgroundCheckRequestGetPriceFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_GET_PRICE_FAILURE,
    payload,
});


export const backgroundCheckRequestMakePaymentRequest = (payload:BackgroundCheckRequestMakePaymentPayload):BackgroundCheckRequestMakePaymentRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_MAKE_PAYMENT_REQUEST,
    payload,
});
export const backgroundCheckRequestMakePaymentSuccess = (payload:BackgroundCheckRequestMakePaymentSuccessPayload):BackgroundCheckRequestMakePaymentSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_MAKE_PAYMENT_SUCCESS,
    payload,
});
export const backgroundCheckRequestMakePaymentFailure = (payload:BackgroundCheckRequestMakePaymentFailurePayload):BackgroundCheckRequestMakePaymentFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_MAKE_PAYMENT_FAILURE,
    payload,
});


export const backgroundCheckRequestCandidatePaymentDetailsRequest = (payload:BackgroundCheckRequestCandidatePaymentDetailsPayload):BackgroundCheckRequestCandidatePaymentDetailsRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_CANDIDATE_PAYMENT_DETAILS_REQUEST,
    payload,
});
export const backgroundCheckRequestCandidatePaymentDetailsSuccess = (payload:BackgroundCheckRequestCandidatePaymentDetailsSuccessPayload):BackgroundCheckRequestCandidatePaymentDetailsSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_CANDIDATE_PAYMENT_DETAILS_SUCCESS,
    payload,
});
export const backgroundCheckRequestCandidatePaymentDetailsFailure = (payload:BackgroundCheckRequestCandidatePaymentDetailsFailurePayload):BackgroundCheckRequestCandidatePaymentDetailsFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_CANDIDATE_PAYMENT_DETAILS_FAILURE,
    payload,
});


export const backgroundCheckRequestCandidatePaymentMpessaInitRequest = (payload:BackgroundCheckRequestCandidatePaymentMpessaInitPayload):BackgroundCheckRequestCandidatePaymentMpessaInitRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_CANDIDATE_PAYMENT_MPESSA_INIT_REQUEST,
    payload,
});
export const backgroundCheckRequestCandidatePaymentMpessaInitSuccess = (payload:BackgroundCheckRequestCandidatePaymentMpessaInitSuccessPayload):BackgroundCheckRequestCandidatePaymentMpessaInitSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_CANDIDATE_PAYMENT_MPESSA_INIT_SUCCESS,
    payload,
});
export const backgroundCheckRequestCandidatePaymentMpessaInitFailure = (payload:BackgroundCheckRequestCandidatePaymentMpessaInitFailurePayload):BackgroundCheckRequestCandidatePaymentMpessaInitFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_CANDIDATE_PAYMENT_MPESSA_INIT_FAILURE,
    payload,
});


export const backgroundCheckRequestCandidatePaymentMpessaVerifyRequest = (payload:BackgroundCheckRequestCandidatePaymentMpessaVerifyPayload):BackgroundCheckRequestCandidatePaymentMpessaVerifyRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_CANDIDATE_PAYMENT_MPESSA_VERIFY_REQUEST,
    payload,
});
export const backgroundCheckRequestCandidatePaymentMpessaVerifySuccess = (payload:BackgroundCheckRequestCandidatePaymentMpessaVerifySuccessPayload):BackgroundCheckRequestCandidatePaymentMpessaVerifySuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_CANDIDATE_PAYMENT_MPESSA_VERIFY_SUCCESS,
    payload,
});
export const backgroundCheckRequestCandidatePaymentMpessaVerifyFailure = (payload:BackgroundCheckRequestCandidatePaymentMpessaVerifyFailurePayload):BackgroundCheckRequestCandidatePaymentMpessaVerifyFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_CANDIDATE_PAYMENT_MPESSA_VERIFY_FAILURE,
    payload,
});


export const backgroundCheckRequestReportAdjudicationRequest = (payload:BackgroundCheckRequestReportAdjudicationPayload):BackgroundCheckRequestReportAdjudicationRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_ADJUDICATION_REQUEST,
    payload,
});
export const backgroundCheckRequestReportAdjudicationSuccess = (payload:BackgroundCheckRequestReportAdjudicationSuccessPayload):BackgroundCheckRequestReportAdjudicationSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_ADJUDICATION_SUCCESS,
    payload,
});
export const backgroundCheckRequestReportAdjudicationFailure = (payload:BackgroundCheckRequestReportAdjudicationFailurePayload):BackgroundCheckRequestReportAdjudicationFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REPORT_ADJUDICATION_FAILURE,
    payload,
});


export const backgroundCheckRequestReferenceAnswerUploadRequest = (payload:BackgroundCheckRequestReferenceAnswerUploadPayload):BackgroundCheckRequestReferenceAnswerUploadRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REFERENCE_ANSWER_UPLOAD_REQUEST,
    payload,
});
export const backgroundCheckRequestReferenceAnswerUploadSuccess = (payload:BackgroundCheckRequestReferenceAnswerUploadSuccessPayload):BackgroundCheckRequestReferenceAnswerUploadSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REFERENCE_ANSWER_UPLOAD_SUCCESS,
    payload,
});
export const backgroundCheckRequestReferenceAnswerUploadFailure = (payload:BackgroundCheckRequestReferenceAnswerUploadFailurePayload):BackgroundCheckRequestReferenceAnswerUploadFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REFERENCE_ANSWER_UPLOAD_FAILURE,
    payload,
});


export const backgroundCheckRequestReferenceInfoRequest = (payload:BackgroundCheckRequestReferenceInfoPayload):BackgroundCheckRequestReferenceInfoRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REFERENCE_INFO_REQUEST,
    payload,
});
export const backgroundCheckRequestReferenceInfoSuccess = (payload:BackgroundCheckRequestReferenceInfoSuccessPayload):BackgroundCheckRequestReferenceInfoSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REFERENCE_INFO_SUCCESS,
    payload,
});
export const backgroundCheckRequestReferenceInfoFailure = (payload:BackgroundCheckRequestReferenceInfoFailurePayload):BackgroundCheckRequestReferenceInfoFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REFERENCE_INFO_FAILURE,
    payload,
});


export const backgroundCheckRequestReferenceOrgRequest = (payload:BackgroundCheckRequestReferenceOrgPayload):BackgroundCheckRequestReferenceOrgRequest => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REFERENCE_ORG_REQUEST,
    payload,
});
export const backgroundCheckRequestReferenceOrgSuccess = (payload:BackgroundCheckRequestReferenceOrgSuccessPayload):BackgroundCheckRequestReferenceOrgSuccess => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REFERENCE_ORG_SUCCESS,
    payload,
});
export const backgroundCheckRequestReferenceOrgFailure = (payload:BackgroundCheckRequestReferenceOrgFailurePayload):BackgroundCheckRequestReferenceOrgFailure => ({
    type: actionTypes.BACKGROUND_CHECK_REQUEST_REFERENCE_ORG_FAILURE,
    payload,
});


// export const backgroundCheckRequestFilterRequest = (payload:BackgroundCheckRequestFilterPayload):BackgroundCheckRequestFilterRequest => ({
//     type: actionTypes.BACKGROUND_CHECK_REQUEST_FILTER_REQUEST,
//     payload,
// });
// export const backgroundCheckRequestFilterSuccess = (payload:BackgroundCheckRequestFilterSuccessPayload):BackgroundCheckRequestFilterSuccess => ({
//     type: actionTypes.BACKGROUND_CHECK_REQUEST_FILTER_SUCCESS,
//     payload,
// });
// export const backgroundCheckRequestFilterFailure = (payload:BackgroundCheckRequestFilterFailurePayload):BackgroundCheckRequestFilterFailure => ({
//     type: actionTypes.BACKGROUND_CHECK_REQUEST_FILTER_FAILURE,
//     payload,
// });
