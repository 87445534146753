// import { apiPerkDiscountReducer } from './reports/index';
import { combineReducers } from 'redux'
import loginReducer from './auth/login'
import { initSignUpReducer, clearSignUpEmailReducer } from './auth/signUp/initialize'
import { confirmSignUpReducer, resendSignUpOTPReducer } from './auth/signUp/confirm'
import setPasswordReducer from './auth/signUp/setPassword'
import initResetPasswordReducer from './auth/resetPassword/initializeResetPassword'
import confirmResetPasswordReducer from './auth/resetPassword/confirmResetPassword'
import acceptInviteReducer from './auth/acceptInvite/'
import {accessTokenInfoReducer, migrationSetPasswordReducer, tenantInfoReducer} from './accessToken/'
import {tourGuideReducer} from './tourGuide'
import { 
    dashboardInfoReducer, 
    announcementReducer, 
    viewAnnouncementReducer,
    acceptIndemnityFormReducer
} from './dashboard'
import {
    walletHistoryReducer,
    walletBalanceReducer,
    cardInfoReducer,
    addCardReducer,
    setDefaultCardReducer,
    removeCardReducer,
    setThresholdReducer,
    virtualAccountInfoReducer,
    topUpWalletReducer,
    paystackTopUpWalletReducer,
    flutterwaveTopUpWalletReducer,
    walletToWalletTransferReducer,
    mpessaTopUpWalletReducer,
} from './wallet'
import {
    myOrganisationInfoReducer,
    createMyOrganisationInfoReducer,
    updateMyOrganisationInfoReducer,
} from './myOrganisation'
import {
    regenerateLiveKeyReducer,
    regenerateSandboxKeyReducer,
} from './apiLibraries/apiKeys'
import {
    applicationInfoReducer,
    createApplicationReducer,
    editApplicationReducer,
    getAppTeamMembersInfoReducer
} from './apiLibraries/applications'
import { apiStatusReducer } from './apiLibraries/status'
import { updateWebhookUrlReducer } from './sdkLibraries'
import { updateProfileInfoReducer, updateUserPasswordReducer } from './settings/profileInfo'
import { organisationInfoReducer, updateOrganisationInfoReducer } from './settings/organisationInfo'
import {
    organizationModuleReducer,
    organizationRoleReducer,
    createUserRoleReducer,
    updateUserRoleReducer,
} from './settings/roles'
import {
    preferenceInfoReducer,
    updatePreferenceInfoReducer,
    contactInfoReducer,
    addContactInfoReducer,
    removeContactInfoReducer,
} from './settings/notificationSettings'
import {
    teamInfoReducer,
    createTeamMemberReducer,
    activateTeamMemberReducer,
    deactivateTeamMemberReducer,
    deleteTeamMemberReducer,
    changeTeamMemberRoleReducer,
} from './settings/team'
import {
    identitypassVerificationReducer,
    identitypassEndpointsReducer,
    identitypassBulkVerificationReducer,
    identitypassBulkHistoryReducer,
} from './products/identitypass/verification'
import {
    identityPassCreateWidgetReducer,
    identityPassDeleteWidgetReducer,
    identityPassEndpointsReducer,
    identityPassGetWidgetReducer,
    identityPassGetWidgetsReducer,
    identityPassUpdateWidgetReducer,
} from './products/identitypass/checker-widget'
import {
    backgroundCheckPackageGetAllReducer,
    backgroundCheckPackageGetBaseChecksReducer,
    backgroundCheckPackageGetBaseChecksSubserviceReducer,
    backgroundCheckPackageCreateReducer,
    backgroundCheckPackageDeleteReducer,
    backgroundCheckPackageUpdateReducer,
    backgroundCheckPackageFilterReducer,
    backgroundCheckPackageGetSingleReducer,
    backgroundCheckPackageGetActiveReducer,
    backgroundCheckPackageChangeStatusReducer,
    backgroundCheckPackageRequestsCostsReducer,
} from './products/backgroundCheck/package'
import {
    backgroundCheckRequestGetAllReducer,
    backgroundCheckRequestEmailCheckInitiateReducer,
    backgroundCheckRequestInitiateReducer,
    backgroundCheckRequestReinitiateReducer,
    backgroundCheckRequestConsentReducer,
    backgroundCheckRequestConsentRejectReducer,
    backgroundCheckRequestFilterReducer,
} from './products/backgroundCheck/request/general'
import {
    backgroundCheckRequestGetCandidateFormReducer,
    backgroundCheckRequestValidateCandidateFormReducer,
    backgroundCheckRequestGetPackageCandidateFormReducer,
    backgroundCheckRequestCreateCandidateFormReducer,
    backgroundCheckRequestAnswerUploadReducer,
    backgroundCheckRequestFileUploadReducer,
    backgroundCheckRequestReportOverviewReducer,
    backgroundCheckRequestReportChecklistReducer,
    backgroundCheckRequestReportDetailReducer,
    backgroundCheckRequestReportDetailDownloadReducer,
    backgroundCheckRequestReportDownloadReducer,
    backgroundCheckRequestReportSetStatusReducer,
    backgroundCheckRequestGetPriceReducer,
    backgroundCheckRequestMakePaymentReducer,
    backgroundCheckRequestCandidatePaymentDetailsReducer,
    backgroundCheckRequestCandidatePaymentMpessaInitReducer,
    backgroundCheckRequestCandidatePaymentMpessaVerifyReducer,
    backgroundCheckRequestGetSubmittedFormReducer,
    backgroundCheckRequestUpdateSubmittedFormReducer,
    backgroundCheckRequestReportAdjudicationReducer,
    backgroundCheckRequestReferenceAnswerUploadReducer,
    backgroundCheckRequestReferenceInfoReducer,
    backgroundCheckRequestReferenceOrgReducer,
} from './products/backgroundCheck/request/checks'
// import {
//     apiPerksDiscountReducer,
//     apiAllPerksDiscountReducer,
//     addFavouritesPerksDiscountReducer,
//     searchPerksDiscountReducer,
//     ecommercePerksDiscountReducer,
//     salesMarketPerksDiscountReducer,
//     financePerksDiscountReducer,
//     developerToolsPerksDiscountReducer,
//     fundingPerksDiscountReducer,
//     apiFavouritesPerksDiscountReducer
// } from './perkDiscount'
import {
    subPlansReducer,
    subPlansByTenureReducer,
    subscriptionReducer,
    subLogsReducer,
    currentSubReducer,
    subPricingReducer,
} from './subscription'
import {
    referralCommissionBalanceReducer,
    referralHistoryReducer,
    referralOverviewReducer,
    referralLinkReducer,
    allRefereesReducer,
    referralReportReducer,
    referralCommissionWithdrawalReducer,
    referralGraphReducer,
    referralFeedbackReducer,
} from './referral'
import { notificationsInfoReducer, readNotificationReducer } from './notifications'
import { backgroundCheckReportCheckerGetReportReducer } from './products/backgroundCheck/reportChecker'
// import { perksAndDiscountInfoReducer } from './perksAndDiscount'

// import {getPolicyReducer, getPolicyTemplateReducer, adoptPolicyTemplateReducer, createPolicyTemplateReducer, deletePolicyReducer, editPolicyReducer, partialUpdatePolicyReducer} from './policies'

export const rootReducers = combineReducers({
    loginReducer,
    initSignUpReducer,
    clearSignUpEmailReducer,
    confirmSignUpReducer,
    resendSignUpOTPReducer,
    setPasswordReducer,
    initResetPasswordReducer,
    confirmResetPasswordReducer,
    acceptInviteReducer,
    accessTokenInfoReducer,
    tenantInfoReducer,
    migrationSetPasswordReducer,



    tourGuideReducer,
    dashboardInfoReducer,
    announcementReducer,
    viewAnnouncementReducer,
    acceptIndemnityFormReducer,


    walletHistoryReducer,
    walletBalanceReducer,
    cardInfoReducer,
    addCardReducer,
    setDefaultCardReducer,
    removeCardReducer,
    setThresholdReducer,
    virtualAccountInfoReducer,
    topUpWalletReducer,
    paystackTopUpWalletReducer,
    flutterwaveTopUpWalletReducer,
    walletToWalletTransferReducer,
    mpessaTopUpWalletReducer,


    myOrganisationInfoReducer,
    createMyOrganisationInfoReducer,
    updateMyOrganisationInfoReducer,
    organisationInfoReducer,
    updateOrganisationInfoReducer,


    updateUserPasswordReducer,
    updateProfileInfoReducer,
    regenerateLiveKeyReducer,
    regenerateSandboxKeyReducer,
    applicationInfoReducer,
    createApplicationReducer,
    editApplicationReducer,
    getAppTeamMembersInfoReducer,
    apiStatusReducer,
    updateWebhookUrlReducer,


    organizationModuleReducer,
    organizationRoleReducer,
    createUserRoleReducer,
    updateUserRoleReducer,
    preferenceInfoReducer,
    updatePreferenceInfoReducer,
    contactInfoReducer,
    addContactInfoReducer,
    removeContactInfoReducer,
    teamInfoReducer,
    createTeamMemberReducer,
    changeTeamMemberRoleReducer,
    activateTeamMemberReducer,
    deactivateTeamMemberReducer,
    deleteTeamMemberReducer,



    identitypassVerificationReducer,
    identitypassEndpointsReducer,
    identitypassBulkVerificationReducer,
    identitypassBulkHistoryReducer,
    identityPassCreateWidgetReducer,
    identityPassUpdateWidgetReducer,
    identityPassDeleteWidgetReducer,
    identityPassGetWidgetsReducer,
    identityPassGetWidgetReducer,
    identityPassEndpointsReducer,


    backgroundCheckPackageGetAllReducer,
    backgroundCheckPackageGetBaseChecksReducer,
    backgroundCheckPackageGetBaseChecksSubserviceReducer,
    backgroundCheckPackageCreateReducer,
    backgroundCheckPackageDeleteReducer,
    backgroundCheckPackageUpdateReducer,
    backgroundCheckPackageFilterReducer,
    backgroundCheckPackageGetSingleReducer,
    backgroundCheckPackageGetActiveReducer,
    backgroundCheckPackageChangeStatusReducer,
    backgroundCheckPackageRequestsCostsReducer,


    backgroundCheckRequestGetAllReducer,
    backgroundCheckRequestEmailCheckInitiateReducer,
    backgroundCheckRequestInitiateReducer,
    backgroundCheckRequestReinitiateReducer,
    backgroundCheckRequestConsentReducer,
    backgroundCheckRequestConsentRejectReducer,
    backgroundCheckRequestFilterReducer,


    backgroundCheckRequestGetCandidateFormReducer,
    backgroundCheckRequestGetSubmittedFormReducer,
    backgroundCheckRequestUpdateSubmittedFormReducer,
    backgroundCheckRequestValidateCandidateFormReducer,
    backgroundCheckRequestGetPackageCandidateFormReducer,
    backgroundCheckRequestCreateCandidateFormReducer,
    backgroundCheckRequestAnswerUploadReducer,
    backgroundCheckRequestFileUploadReducer,
    backgroundCheckRequestReportOverviewReducer,
    backgroundCheckRequestReportChecklistReducer,
    backgroundCheckRequestReportDetailReducer,
    backgroundCheckRequestReportDetailDownloadReducer,
    backgroundCheckRequestReportDownloadReducer,
    backgroundCheckRequestReportSetStatusReducer,
    backgroundCheckRequestGetPriceReducer,
    backgroundCheckRequestMakePaymentReducer,
    backgroundCheckRequestCandidatePaymentDetailsReducer,
    backgroundCheckRequestCandidatePaymentMpessaInitReducer,
    backgroundCheckRequestCandidatePaymentMpessaVerifyReducer,
    backgroundCheckRequestReportAdjudicationReducer,
    backgroundCheckRequestReferenceAnswerUploadReducer,
    backgroundCheckRequestReferenceInfoReducer,
    backgroundCheckRequestReferenceOrgReducer,


    backgroundCheckReportCheckerGetReportReducer,

    
    // apiPerksDiscountReducer,
    // apiAllPerksDiscountReducer,
    // addFavouritesPerksDiscountReducer,
    // searchPerksDiscountReducer,
    // ecommercePerksDiscountReducer,
    // salesMarketPerksDiscountReducer,
    // financePerksDiscountReducer,
    // developerToolsPerksDiscountReducer,
    // fundingPerksDiscountReducer,
    // apiFavouritesPerksDiscountReducer,



    subPlansReducer,
    subPlansByTenureReducer,
    subscriptionReducer,
    subLogsReducer,
    currentSubReducer,
    subPricingReducer,



    referralCommissionBalanceReducer,
    referralHistoryReducer,
    referralOverviewReducer,
    referralLinkReducer,
    allRefereesReducer,
    referralReportReducer,
    referralCommissionWithdrawalReducer,
    referralGraphReducer,
    referralFeedbackReducer,



    notificationsInfoReducer,
    readNotificationReducer,


    // getPolicyReducer,
    // getPolicyTemplateReducer,
    // adoptPolicyTemplateReducer,
    // createPolicyTemplateReducer,
    // deletePolicyReducer,
    // editPolicyReducer,
    // partialUpdatePolicyReducer

})

export type RootState = ReturnType<typeof rootReducers>
